<template>
  <div
    class="full-modal fixed inset-0 z-30 flex items-center overflow-y-auto p-4 opacity-0"
    :class="{ invisible: !modelValue, 'opacity-100': modelValue }"
    style="backdrop-filter: blur(8px); transition: visibility ease 0.3s, opacity ease 0.3s;"
    @click="$emit('update:modelValue', false)"
  >
    <div ref="container" class="modal-content relative m-auto w-full max-w-160 overflow-hidden rounded-xl bg-white shadow-lg" :class="contentClass" @click.stop>
      <button v-if="showClose" class="modal-close-button absolute right-0 top-0 z-10 mr-4 mt-4" @click="$emit('update:modelValue', false)">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.70718 9.12124L1.41421 15.4142L0 14L6.29297 7.70703L0.000150681 1.41421L1.41436 0L7.70718 6.29282L14 0L15.4142 1.41421L9.1214 7.70703L15.4144 14L14.0002 15.4142L7.70718 9.12124Z" fill="#4D4D4D" />
        </svg>
      </button>
      <slot />
    </div>
  </div>
</template>

<script>
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock'

export default {
  props: {
    modelValue: { type: Boolean, required: true },
    showClose: { type: Boolean, default: true },
    contentClass: { type: String, default: 'md:w-160' },
  },

  emits: ['update:modelValue'],

  watch: {
    modelValue (val) {
      if (val) {
        disableBodyScroll(this.$refs.container)
      } else {
        clearAllBodyScrollLocks()
      }
    },
  },

  beforeUnmount () {
    clearAllBodyScrollLocks()
  },
}
</script>
